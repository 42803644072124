import { FILTER_TABLE } from '../../../filter/filter';

//Data 파일에서 제공하지 않는 데이터를 모아둔 표입니다.

export interface MissingSquadData {
  id: string;
  name: string;
  nameKO: string;
  briefText?: string;
  filters?: string[];
  defaultWeapons?: string[];
}

//key는 squad의 id(데이터 파일이름)입니다.
interface MissingSquadDataTable {
  [key: string]: MissingSquadData;
}

const missingSquadDataTable: MissingSquadDataTable = {
  //afrika_korps - infantry
  assault_panzergrenadier_ak: {
    id: 'assault_panzergrenadier_ak',
    name: 'Assault Grenadier Squad',
    nameKO: '강습 척탄병',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.MAINLINE.en],
    briefText: '대보병 / 주력',
  },
  bersaglieri_ak: {
    id: 'bersaglieri_ak',
    name: 'Bersaglieri',
    nameKO: '베르살리에리',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.MAINLINE.en],
    briefText: '대보병 / 주력',
    defaultWeapons: ['w_lmg_breda_m30_bersaglieri_ak'],
  },
  guastatori_ak: {
    id: 'guastatori_ak',
    name: 'Guastatori Squad',
    nameKO: '이탈리아 공병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_flamethrower_guastatori_ak'],
  },
  panzergrenadier_ak: {
    id: 'panzergrenadier_ak',
    name: 'Panzergrenadier Squad',
    nameKO: '기갑척탄병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.MAINLINE.en],
    briefText: '대보병 / 주력',
    defaultWeapons: ['w_lmg_panzergrenadier_ak'],
  },
  panzerjaeger_inf_ak: {
    id: 'panzerjaeger_inf_ak',
    name: 'PanzerJäger Squad',
    nameKO: '기갑엽병 분대',
    filters: [FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.SUPPORT.en],
    briefText: '대차량 / 지원',
    defaultWeapons: ['w_panzerbuchse39_at_rifle_ak', 'w_lmg_panzerjaeger_ak'],
  },
  panzerpioneer_ak: {
    id: 'panzerpioneer_ak',
    name: 'Panzerpioneer Squad',
    nameKO: '기갑공병대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_flamethrower_panzerpioneer_ak', 'w_grb_39_grenade_launcher_he_ak'],
  },

  //afrika_korps - team_weapons
  at_gun_50mm_pak_38_ak: {
    id: 'at_gun_50mm_pak_38_ak',
    name: 'Pak 38 Anti-tank Gun Team',
    nameKO: 'Pak 38 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en],
    briefText: '대전차 / 중화기',
  },
  at_gun_88mm_mobile_ak: {
    id: 'at_gun_88mm_mobile_ak',
    name: 'Flak 36 Anti-tank Gun Team',
    nameKO: 'Flak 36 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.ANTI_AIRCRAFT.en],
    briefText: '대전차 / 중화기',
  },
  hmg_mg34_ak: {
    id: 'hmg_mg34_ak',
    name: 'MG34 Team',
    nameKO: 'MG34 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en],
    briefText: '대보병 / 중화기',
  },

  howitzer_cannone_da_105_ak: {
    id: 'howitzer_cannone_da_105_ak',
    name: 'Cannone da 105/28 Howitzer Team',
    nameKO: '카노네 다 105/28 곡사포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '중형포',
    defaultWeapons: ['w_105mm_howitzer_cannone_barrage_ak'],
  },
  leig_75mm_ak: {
    id: 'leig_75mm_ak',
    name: 'le.IG 18 Support Gun Team',
    nameKO: 'le.IG 18 경포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '대보병 / 중화기',
    defaultWeapons: ['w_75mm_barrage_leig_ak'],
  },

  //afrika_korps - vehicles
  armored_car_8_rad_ak: {
    id: 'armored_car_8_rad_ak',
    name: '8 Rad Armored Car',
    nameKO: '8륜 장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병',
  },
  armored_tractor_254_ak: {
    id: 'armored_tractor_254_ak',
    name: '254 Reconnaissance Tractor',
    nameKO: '254 정찰 트랙터',
    filters: [
      FILTER_TABLE.NON_COMBATANT.en,
      FILTER_TABLE.SUPPORT.en,
      FILTER_TABLE.RECON.en,
      FILTER_TABLE.LIGHT_VEHICLE.en,
    ],
    briefText: '비전투 / 지원',
  },
  halftrack_250_20mm_ak: {
    id: 'halftrack_250_20mm_ak',
    name: '250/9 Armored Half-Track',
    nameKO: '250/9 반궤도장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병',
  },
  halftrack_250_ak: {
    id: 'halftrack_250_ak',
    name: '250 Light Carrier',
    nameKO: '250 경수송차량',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 지원',
  },
  halftrack_250_mortar_ak: {
    id: 'halftrack_250_mortar_ak',
    name: '250 Mortar_Half-Track',
    nameKO: '박격포 반궤도차량',
    filters: [
      FILTER_TABLE.ANTI_INFANTRY.en,
      FILTER_TABLE.ANTI_STRUCTURE.en,
      FILTER_TABLE.ARTILLERY.en,
      FILTER_TABLE.ARMORED_CAR.en,
    ],
    briefText: '대보병 / 구조물 철거',
    defaultWeapons: ['w_81mm_barrage_mortar_halftrack_ak'],
  },
  halftrack_251_stuka_ak: {
    id: 'halftrack_251_stuka_ak',
    name: 'Walking Stuka Rocket Launcher',
    nameKO: '워킹 슈투카 로켓 발사대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '로켓포',
    defaultWeapons: ['w_280mm_stuka_zufuss_rocket_ak'],
  },
  halftrack_7_flak_ak: {
    id: 'halftrack_7_flak_ak',
    name: 'Flakvierling Half-track',
    nameKO: '플락피엘링 반궤도차량',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_AIRCRAFT.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '대보병 / 대공',
  },
  halftrack_recovery_ak: {
    id: 'halftrack_recovery_ak',
    name: '18-tonne Recovery Half-track',
    nameKO: '18톤 반궤도 구난장갑차',
    filters: [FILTER_TABLE.NON_COMBATANT.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '비전투 / 지원',
  },
  kradschutzen_motorcycle_ak: {
    id: 'kradschuntzen_motorcycle_ak',
    name: 'Kradschützen Motorcycle Team',
    nameKO: '크라트슈첸 바이크 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '대보병 / 정찰',
  },
  l6_40_ak: {
    id: 'l6_40_ak',
    name: 'L6/40 Light Tank',
    nameKO: 'L6/40 경전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.LIGHT_TANK.en],
    briefText: '대보병',
    defaultWeapons: ['w_flamethrower_l6_40_ak'],
  },
  m13_40_ak: {
    id: 'm13_40_ak',
    name: 'Carro Armato M13/40 Light Tank',
    nameKO: '카로 아르마토 M13/40 경전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.LIGHT_TANK.en],
    briefText: '대보병 / 대차량',
  },
  marder_iii_ak: {
    id: 'marder_iii_ak',
    name: 'Marder III Tank Destroyer',
    nameKO: '마르더 III 구축전차',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.TANK_DESTROYER.en],
    briefText: '대전차',
  },
  panzer_iii_50mm_long_ak: {
    id: 'panzer_iii_50mm_long_ak',
    name: 'Panzer III L Medium Tank',
    nameKO: '3호 L형 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
    defaultWeapons: ['w_mg34_panzer_iii_50mm_long_ak'],
  },
  panzer_iii_flame_ak: {
    id: 'panzer_iii_flame_ak',
    name: 'Flammpanzer III Medium Tank',
    nameKO: '플람판처 3호 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_STRUCTURE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 구조물 철거',
  },
  panzer_iv_ak: {
    id: 'panzer_iv_ak',
    name: 'Panzer IV Sd.Kfz. 161/1',
    nameKO: '4호전차 Sd.Kfz. 161/1',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
    defaultWeapons: ['w_mg42_panzer_iv_ak'],
  },
  panzer_iv_command_ak: {
    id: 'panzer_iv_command_ak',
    name: 'Command Panzer IV medium Tank',
    nameKO: '4호 중형 지휘전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 지원',
  },
  semovente_75_18_ak: {
    id: 'semovente_75_18_ak',
    name: 'Semovente da 75/18 Assault Gun',
    nameKO: '세모벤테 다 75/18 돌격포',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.ASSAULT_GUN.en],
    briefText: '대전차',
    defaultWeapons: ['w_75mm_semovente_barrage_ak'],
  },
  stug_iii_ak: {
    id: 'stug_iii_ak',
    name: 'StuG III Assault Gun Sd.Kfz. 142',
    nameKO: '스터그 III 돌격포 Sd.Kfz. 142',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.ASSAULT_GUN.en],
    briefText: '대전차',
    defaultWeapons: ['w_mg42_stug_iii_ak'],
  },
  stug_iii_d_ak: {
    id: 'stug_iii_d_ak',
    name: 'StuG III D Assault Gun',
    nameKO: '스터그 III D형 돌격포',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_STRUCTURE.en, FILTER_TABLE.ASSAULT_GUN.en],
    briefText: '대보병 / 구조물 철거',
    defaultWeapons: ['w_mg42_stug_iii_d_ak'],
  },
  tiger_ak: {
    id: 'tiger_ak',
    name: 'Tiger Heavy Tank',
    nameKO: '티거 중전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.HEAVY_TANK.en],
    briefText: '대보병 / 대전차',
  },
  truck_2_5_ak: {
    id: 'truck_2_5_ak',
    name: '2.5-tonne Utility Truck',
    nameKO: '2.5톤 다용도 트럭',
    filters: [FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '',
  },
  truck_2_5_medical_ak: {
    id: 'truck_2_5_medical_ak',
    name: '2.5-tonne Medical Truck',
    nameKO: '2.5톤 의료 트럭',
    filters: [
      FILTER_TABLE.NON_COMBATANT.en,
      FILTER_TABLE.SUPPORT.en,
      FILTER_TABLE.MEDIC.en,
      FILTER_TABLE.LIGHT_VEHICLE.en,
    ],
    briefText: '비전투 / 지원',
  },

  //american - infantry
  assault_engineer_us: {
    id: 'assault_engineer_us',
    name: 'Assault Engineer Squad',
    nameKO: '돌격 공병',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_flamethrower_engineer_us'],
  },
  bazooka_team_us: {
    id: 'bazooka_team_us',
    name: 'Bazooka Squad',
    nameKO: '바주카 분대',
    filters: [FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.SUPPORT.en],
    briefText: '대차량 / 지원',
    defaultWeapons: ['w_bazooka_bazooka_team_us'],
  },
  captain_us: {
    id: 'captain_us',
    name: 'Captain Retinue',
    nameKO: '대위 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_m1919a6_paratrooper_us'],
  },
  engineer_us: {
    id: 'engineer_us',
    name: 'Engineer Squad',
    nameKO: '공병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_flamethrower_engineer_us'],
  },
  paratrooper_us: {
    id: 'paratrooper_us',
    name: 'Paratrooper Squad',
    nameKO: '낙하산병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_m1919a6_paratrooper_us', 'w_bazooka_paratroopers_us'],
  },
  pathfinder_us: {
    id: 'pathfinder_us',
    name: 'Pathfinder Squad',
    nameKO: '항로선도대 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en],
    briefText: '대보병 / 정찰',
  },
  riflemen_us: {
    id: 'riflemen_us',
    name: 'Riflemen Squad',
    nameKO: '소총병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.MAINLINE.en],
    briefText: '대보병 / 주력',
    defaultWeapons: ['w_bar_riflemen_us'],
  },
  scout_us: {
    id: 'scout_us',
    name: 'Scout Squad',
    nameKO: '정찰병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en],
    briefText: '대보병 / 정찰',
  },
  sniper_us: {
    id: 'sniper_us',
    name: 'Sniper',
    nameKO: '저격수',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.SNIPER.en],
    briefText: '대보병 / 지원',
  },
  ssf_commandos_us: {
    id: 'ssf_commandos_us',
    name: 'SSF Commando Squad',
    nameKO: 'SSF 코만도 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_m1941_lmg_devils_brigade_us', 'w_bazooka_devils_brigade_us'],
  },

  //american - team_weapons
  at_gun_57mm_us: {
    id: 'at_gun_57mm_us',
    name: 'M1 Anti-tank Gun Team',
    nameKO: 'M1 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en],
    briefText: '대전차 / 중화기',
  },
  hmg_30cal_us: {
    id: 'hmg_30cal_us',
    name: 'M1919 Machine Gun Team',
    nameKO: 'M1919 기관총 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en],
    briefText: '대보병 / 중화기',
  },
  mortar_81mm_us: {
    id: 'mortar_81mm_us',
    name: 'M1 Mortar Team',
    nameKO: 'M1 박격포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '대보병 / 중화기',
    defaultWeapons: ['w_81mm_barrage_mortar_us'],
  },

  //american - vehicles
  chaffee_us: {
    id: 'chaffee_us',
    name: 'M24 Chaffee Light Tank',
    nameKO: 'M24 채피 경전차',
    filters: [FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.LIGHT_TANK.en],
    briefText: '대차량',
  },
  greyhound_us: {
    id: 'greyhound_us',
    name: 'M8 Greyhound Armored Car',
    nameKO: 'M8 그레이하운드 장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 대차량',
    defaultWeapons: ['w_50cal_greyhound_us'],
  },
  halftrack_75mm_us: {
    id: 'halftrack_75mm_us',
    name: 'M3 75mm Gun Motor Carriage',
    nameKO: 'M3 75mm 주포 무장 장갑차',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대전차',
    defaultWeapons: ['w_75mm_howitzer_barrage_halftrack_75mm_us'],
  },
  halftrack_medical_us: {
    id: 'halftrack_medical_us',
    name: 'M3 Armored Medical Half-track',
    nameKO: 'M3 반궤도 구급 장갑차',
    filters: [
      FILTER_TABLE.NON_COMBATANT.en,
      FILTER_TABLE.SUPPORT.en,
      FILTER_TABLE.MEDIC.en,
      FILTER_TABLE.ARMORED_CAR.en,
    ],
    briefText: '비전투 / 지원',
  },
  halftrack_quad_us: {
    id: 'halftrack_quad_us',
    name: 'M16 Multiple Gun Motor Carriage',
    nameKO: 'M16 다연장 기관총 장착 장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_AIRCRAFT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 대공',
  },
  halftrack_us: {
    id: 'halftrack_us',
    name: 'M3 Armored Personnel Carrier',
    nameKO: 'M3 병력수송 장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 지원',
  },
  hellcat_us: {
    id: 'hellcat_us',
    name: 'M18 Hellcat Tank Destroyer',
    nameKO: 'M18 헬캣 구축 전차',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.TANK_DESTROYER.en],
    briefText: '대전차',
    defaultWeapons: ['w_50cal_hellcat_us'],
  },
  m29_weasal_us: {
    id: 'm29_weasal_us',
    name: 'M29 Weasel Recon Vehicle',
    nameKO: 'M29 위즐 정찰 차량',
    filters: [FILTER_TABLE.NON_COMBATANT.en, FILTER_TABLE.RECON.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '비전투 / 정찰',
    defaultWeapons: ['w_30cal_weasal_us'],
  },
  recovery_vehicle_us: {
    id: 'recovery_vehicle_us',
    name: 'M31 Recovery Vehicle',
    nameKO: 'M31 구난차량',
    filters: [FILTER_TABLE.NON_COMBATANT.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '비전투 / 지원',
  },
  scott_us: {
    id: 'scott_us',
    name: 'M8 Scott SPG',
    nameKO: 'M8 스콧 자주포',
    filters: [
      FILTER_TABLE.ANTI_INFANTRY.en,
      FILTER_TABLE.ANTI_STRUCTURE.en,
      FILTER_TABLE.ARTILLERY.en,
      FILTER_TABLE.SELF_PROPELLED_ARTILLERY.en,
    ],
    briefText: '대보병 / 구조물 철거',
    defaultWeapons: ['w_75mm_howitzer_barrage_scott_us'],
  },
  sherman_76mm_us: {
    id: 'sherman_76mm_us',
    name: 'M4A1(76) Sherman Medium Tank',
    nameKO: 'M4A1(76) 셔먼 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대전차',
    defaultWeapons: ['w_50cal_sherman_us'],
  },
  sherman_bulldozer_us: {
    id: 'sherman_bulldozer_us',
    name: 'M4(105) Sherman Bulldozer',
    nameKO: 'M4(105) 셔먼 불도저',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_STRUCTURE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 구조물 철거',
    defaultWeapons: ['w_50cal_sherman_us_bulldozer'],
  },
  sherman_easy_8_us: {
    id: 'sherman_easy_8_us',
    name: 'M4A3E8 Sherman Easy Eight',
    nameKO: 'M4A3E8 셔먼 이지 에잇',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대전차',
    defaultWeapons: ['w_50cal_sherman_us_base'],
  },
  sherman_us: {
    id: 'sherman_us',
    name: 'M4A1 Sherman Medium Tank',
    nameKO: 'M4A1 셔먼 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
    defaultWeapons: ['w_50cal_sherman_us'],
  },
  sherman_whizbang_us: {
    id: 'sherman_whizbang_us',
    name: 'M4A1 Sherman Whizbang',
    nameKO: 'M4A1 셔먼 위즈뱅',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '로켓포',
    defaultWeapons: ['w_180mm_rocket_barrage_whizbang_us'],
  },
  truck_4x4_us: {
    id: 'truck_4x4_us',
    name: '1/4-ton 4x4 Truck',
    nameKO: '1/4톤 4x4 트럭',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '대보병 / 대차량',
  },

  //british_africa - infantry
  commando_africa_uk: {
    id: 'commando_africa_uk',
    name: 'Commando Section',
    nameKO: '코만도 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
  },
  commando_lmg_africa_uk: {
    id: 'commando_lmg_africa_uk',
    name: 'Commando LMG Section',
    nameKO: '코만도 경기관총 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_bren_lmg_uk'],
  },
  guards_africa_uk: {
    id: 'guards_africa_uk',
    name: 'Foot Guards Section',
    nameKO: '근위 보병대',
    filters: [
      FILTER_TABLE.ANTI_INFANTRY.en,
      FILTER_TABLE.ANTI_VEHICLE.en,
      FILTER_TABLE.GENERALIST.en,
      FILTER_TABLE.ELITE.en,
    ],
    briefText: '범용',
    defaultWeapons: ['w_bazooka_guards_africa_uk'],
  },
  gurkhas_africa_uk: {
    id: 'gurkhas_africa_uk',
    name: 'Gurkha Rifles Section',
    nameKO: '구르카 소총병대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_thompson_gurkhas_uk', 'w_bren_lmg_gurkhas_uk'],
  },
  sapper_africa_uk: {
    id: 'sapper_africa_uk',
    name: 'Royal Engineer Section',
    nameKO: '왕립 공병대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_flamethrower_sapper_africa_uk'],
  },
  tommy_africa_uk: {
    id: 'tommy_africa_uk',
    name: 'Infantry Section',
    nameKO: '보병대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.MAINLINE.en],
    briefText: '대보병 / 주력',
    defaultWeapons: [
      'w_scoped_lee_enfield_tommy_africa_uk',
      'w_boys_anti_tank_rifle_africa_uk',
      'w_bren_lmg_africa_uk',
    ],
  },

  //british_africa - team_weapons
  at_gun_17pdr_mobile_africa_uk: {
    id: 'at_gun_17pdr_mobile_africa_uk',
    name: '17-pounder Anti-tank Gun Team',
    nameKO: '17파운더 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en],
    briefText: '대전차 / 중화기',
  },
  at_gun_6pdr_africa_uk: {
    id: 'at_gun_6pdr_africa_uk',
    name: '6-pounder Anti-tank Gun Team',
    nameKO: '6파운더 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en],
    briefText: '대전차 / 중화기',
  },
  hmg_vickers_africa_uk: {
    id: 'hmg_vickers_africa_uk',
    name: 'Vickers Machine Gun Team',
    nameKO: '빅커스 기관총 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en],
    briefText: '대보병 / 중화기',
  },
  mortar_81mm_africa_uk: {
    id: 'mortar_81mm_africa_uk',
    name: 'ML 3-inch Mortar Team',
    nameKO: 'ML 3인치 박격포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '대보병 / 중화기',
    defaultWeapons: ['w_81mm_barrage_mortar_uk'],
  },
  mortar_heavy_4_2_africa_uk: {
    id: 'mortar_heavy_4_2_africa_uk',
    name: 'ML 4.2-inch Heavy Mortar Team',
    nameKO: 'ML 4.2인치 중박격포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '경포',
    defaultWeapons: ['w_4_2_inch_barrage_heavy_mortar_uk'],
  },
  pack_howitzer_75mm_africa_uk: {
    id: 'pack_howitzer_75mm_africa_uk',
    name: 'M1 Pack Howitzer team',
    nameKO: 'M1 경야포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '경포',
    defaultWeapons: ['w_75mm_pack_howitzer_barrage_us'],
  },

  //british_africa - vehicles
  bishop_africa_uk: {
    id: 'bishop_africa_uk',
    name: 'Bishop Self-propelled Artillery',
    nameKO: '비숍 자주포',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en, FILTER_TABLE.SELF_PROPELLED_ARTILLERY.en],
    briefText: '중형포',
    defaultWeapons: ['w_25pdr_bishop_uk'],
  },
  centaur_africa_uk: {
    id: 'centaur_africa_uk',
    name: 'Centaur IV Medium Tank',
    nameKO: '센토어 4형 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_STRUCTURE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 구조물 철거',
  },
  churchill_africa_uk: {
    id: 'churchill_africa_uk',
    name: 'Churchill IV Heavy Tank',
    nameKO: '처칠 IV 중전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.HEAVY_TANK.en],
    briefText: '대보병 / 대차량',
  },
  churchill_black_prince_africa_uk: {
    id: 'churchil_black_prince_africa_uk',
    name: 'Churchill Balck Prince Heavy Tank',
    nameKO: '처칠 블랙 프린스 중전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.HEAVY_TANK.en],
    briefText: '대보병 / 대전차',
  },
  crusader_57mm_africa_uk: {
    id: 'crusader_57mm_africa_uk',
    name: 'Crusader III Medium Tank',
    nameKO: '크루세이더 III 중형전차',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대전차',
  },
  crusader_aa_africa_uk: {
    id: 'crusader_aa_africa_uk',
    name: 'Crusader AA Medium Tank',
    nameKO: '크루세이더 대공 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_AIRCRAFT.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대공',
  },
  crusader_africa_uk: {
    id: 'crusader_africa_uk',
    name: 'Crusader II Medium Tank',
    nameKO: '크루세이더 II 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
  },
  cwt_15_quad_mount_africa_uk: {
    id: 'cwt_15_quad_mount_africa_uk',
    name: 'CMP 15cwt Anti-arit Truck',
    nameKO: 'CMP 15cwt 대공 트럭',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_AIRCRAFT.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '대보병 / 대공',
  },
  cwt_15_truck_africa_uk: {
    id: 'cwt_15_truck_africa_uk',
    name: 'CMP 15cwt Truck',
    nameKO: 'CMP 15cwt 트럭',
    filters: [FILTER_TABLE.NON_COMBATANT.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '비전투 / 지원',
  },
  cwt_15_truck_medical_africa_uk: {
    id: 'cwt_15_truck_medical_africa_uk',
    name: 'CMP 15cwt Medical Truck',
    nameKO: 'CMP 15cwt 의료 트럭',
    filters: [
      FILTER_TABLE.NON_COMBATANT.en,
      FILTER_TABLE.SUPPORT.en,
      FILTER_TABLE.MEDIC.en,
      FILTER_TABLE.LIGHT_VEHICLE.en,
    ],
    briefText: '비전투 / 지원',
  },
  dingo_africa_uk: {
    id: 'dingo_africa_uk',
    name: 'Dingo Light Scout Car',
    nameKO: '딩고 정찰 경차량',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '대보병 / 정찰',
  },
  grant_africa_uk: {
    id: 'grant_africa_uk',
    name: 'M3 Grant Medium Tank',
    nameKO: 'M3 그랜트 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
  },
  humber_africa_uk: {
    id: 'humber_africa_uk',
    name: 'Humber Armored Car',
    nameKO: '험버 장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병',
  },
  matilda_africa_uk: {
    id: 'matilda_africa_uk',
    name: 'Mtilda II Heavy Tank',
    nameKO: '마틸다 II 중전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.HEAVY_TANK.en],
    briefText: '대보병 / 대차량',
  },
  stuart_africa_uk: {
    id: 'stuart_africa_uk',
    name: 'M3 Stuart Light Tank',
    nameKO: 'M3 스튜어트 경전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.LIGHT_TANK.en],
    briefText: '대보병 / 대차량',
  },

  //german - infantry
  fallschirmjagers_ger: {
    id: 'fallschirmjagers_ger',
    name: 'Fallschirmjäger Squad',
    nameKO: '강하엽병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
  },
  fallschirmpioneers_ger: {
    id: 'fallschirmpioneers_ger',
    name: 'Fallschirmpioneer Squad',
    nameKO: '강하공병대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_mg15_jaeger_ger', 'w_grb_39_grenade_launcher_he_ger'],
  },
  grenadier_ger: {
    id: 'grenadier_ger',
    name: 'Grenadier Squad',
    nameKO: '척탄병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.MAINLINE.en],
    briefText: '대보병 / 주력',
    defaultWeapons: ['w_mp40_grenadier_ger'],
  },
  jaeger_ger: {
    id: 'jaeger_ger',
    name: 'Jäger Squad',
    nameKO: '엽병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_panzerschrek_stormtrooper_ger', 'w_g43_scoped_jaeger_ger'],
  },
  panzergrenadier_ger: {
    id: 'panzergrenadier_ger',
    name: 'Panzergrenadier Squad',
    nameKO: '기갑척탄병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
  },
  pioneer_ger: {
    id: 'pioneer_ger',
    name: 'Pioneer Squad',
    nameKO: '공병대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ENGINEER.en],
    briefText: '대보병 / 지원',
    defaultWeapons: ['w_flamethrower_pioneer_ger'],
  },
  sniper_ger: {
    id: 'sniper_ger',
    name: 'Sniper',
    nameKO: '저격수',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.SNIPER.en],
    briefText: '대보병 / 지원',
  },
  stormtrooper_ger: {
    id: 'stormtrooper_ger',
    name: 'Stoßtruppen Squad',
    nameKO: '충격보병 분대',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ELITE.en],
    briefText: '대보병 / 정예',
    defaultWeapons: ['w_mg42_stormtrooper_ger', 'w_mp44_stormtrooper_ger'],
  },

  //german - team_weapons
  aa_gun_20mm_ger: {
    id: 'aa_gun_20mm_ger',
    name: 'Flak 30 Anti-aircraft Gun Team',
    nameKO: 'Flak 30 대공포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_AIRCRAFT.en],
    briefText: '대보병 / 중화기',
  },
  at_gun_75mm_ger: {
    id: 'at_gun_75mm_ger',
    name: 'Pak 40 Anti-tank Gun Team',
    nameKO: 'Pak 40 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en],
    briefText: '대전차 / 중화기',
  },
  at_gun_lg40_ger: {
    id: 'at_gun_lg40_ger',
    name: 'LG40 Anti-tank Gun Team',
    nameKO: 'LG40 대전차포 운용반',
    filters: [FILTER_TABLE.ANTI_TANK.en],
    briefText: '대전차 / 중화기',
  },
  hmg_mg42_ger: {
    id: 'hmg_mg42_ger',
    name: 'MG42 Machine Gun Team',
    nameKO: 'MG42 기관총 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en],
    briefText: '대보병 / 중화기',
  },
  mortar_81mm_ger: {
    id: 'mortar_81mm_ger',
    name: 'GrW34 Mortar team',
    nameKO: 'GrW 34 박격포 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '대보병 / 중화기',
    defaultWeapons: ['w_81mm_mortar_barrage_ger'],
  },
  nebelwerfer_150mm_ger: {
    id: 'nebelwerfer_150mm_ger',
    name: 'Nebelwerfer 42 Rocket Launcher Team',
    nameKO: '네벨베르퍼 42 로켓발사기 운용반',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en],
    briefText: '로켓포',
    defaultWeapons: ['w_150mm_nebelwerfer_ger'],
  },

  //german - vehicles
  armored_car_8_rad_ger: {
    id: 'armored_car_8_rad_ger',
    name: '8 Rad Armored Car',
    nameKO: '8륜 장갑차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 정찰',
  },
  armored_car_ger: {
    id: 'armored_car_ger',
    name: '221 Scout Car',
    nameKO: '221 정찰 차량',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.RECON.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 정찰',
    defaultWeapons: ['w_20mm_panzerbuchse_armored_car_ger'],
  },
  brummbar_ger: {
    id: 'brummbar_ger',
    name: 'Sturmpanzer IV Brummbär',
    nameKO: '스톰판처 IV 브룸베어',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_STRUCTURE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 구조물 철거',
  },
  halftrack_ger: {
    id: 'halftrack_ger',
    name: '251 Medium Carrier',
    nameKO: '251 중형 수송차량',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 지원',
  },
  halftrack_medical_ger: {
    id: 'halftrack_medical_ger',
    name: 'Medical Half-track',
    nameKO: '반궤도 구급장갑차',
    filters: [FILTER_TABLE.NON_COMBATANT.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '비전투 / 지원',
  },
  halftrack_stummel_ger: {
    id: 'halftrack_stummel_ger',
    name: 'Stummel Half-track',
    nameKO: '스툼멜 반궤도차량',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_STRUCTURE.en, FILTER_TABLE.ARMORED_CAR.en],
    briefText: '대보병 / 구조물 철거',
    defaultWeapons: ['w_75mm_barrage_halftrack_stummel_ger'],
  },
  kettenkrad_ger: {
    id: 'kettenkrad_ger',
    name: 'Kettenkrad Recon Vehicle',
    nameKO: '케텐크라트 정찰차량',
    filters: [
      FILTER_TABLE.NON_COMBATANT.en,
      FILTER_TABLE.SUPPORT.en,
      FILTER_TABLE.RECON.en,
      FILTER_TABLE.LIGHT_VEHICLE.en,
    ],
    briefText: '비전투 / 지원',
  },
  marder_iii_ger: {
    id: 'marder_iii_ger',
    name: 'Marder III M Tank Destroyer',
    nameKO: '마르더 III M형 구축전차',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.TANK_DESTROYER.en],
    briefText: '대전차',
  },
  panther_ger: {
    id: 'panther_ger',
    name: 'Panther Heavy Tank',
    nameKO: '판터 중전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.HEAVY_TANK.en],
    briefText: '대보병 / 대전차',
  },
  panzer_iv_cmd_ger: {
    id: 'panzer_iv_cmd_ger',
    name: 'Command Panzer IV Medium Tank',
    nameKO: '4호 중형 지휘전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
  },
  panzer_iv_ger: {
    id: 'panzer_iv_ger',
    name: 'Panzer IV Medium Tank',
    nameKO: '4호 중형전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대차량',
    // defaultWeapons: ['w_mg42_panzer_iv_ger'] mg42 이미 맵핑 되어있음
  },
  stug_iii_d_ger: {
    id: 'stug_iii_d_ger',
    name: 'StuG III D Assault Gun',
    nameKO: '스터그 III D형 돌격포',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_VEHICLE.en, FILTER_TABLE.ASSAULT_GUN.en],
    briefText: '대보병 / 구조물 철거',
    defaultWeapons: ['w_mg42_stug_iii_ger'],
  },
  stug_iii_ger: {
    id: 'stug_iii_ger',
    name: 'StuG III G Assault Gun',
    nameKO: '스터그 III G형 돌격포',
    filters: [FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.ASSAULT_GUN.en],
    briefText: '대전차',
    defaultWeapons: ['w_mg42_stug_iii_ger'],
  },
  tiger_ger: {
    id: 'tiger_ger',
    name: 'Tiger Heavy Tank',
    nameKO: '티거 중전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_TANK.en, FILTER_TABLE.HEAVY_TANK.en],
    briefText: '대보병 / 대전차',
    defaultWeapons: ['w_mg34_tiger_ger'],
  },

  truck_2_5_ger: {
    id: 'truck_2_5_ger',
    name: '2.5-tonne Utility Truck',
    nameKO: '2.5톤 다용도 트럭',
    filters: [FILTER_TABLE.NON_COMBATANT.en, FILTER_TABLE.SUPPORT.en, FILTER_TABLE.LIGHT_VEHICLE.en],
    briefText: '비전투 / 지원',
  },

  wespe_ger: {
    id: 'wespe_ger',
    name: 'Wespe Self-propelled Artillery',
    nameKO: '베스페 자주포',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ARTILLERY.en, FILTER_TABLE.SELF_PROPELLED_ARTILLERY.en],
    briefText: '중형포',
    defaultWeapons: ['w_105mm_wespe_ger'],
  },

  wirbelwind_ger: {
    id: 'wirbelwind_ger',
    name: 'Wirbelwind Flakpanzer',
    nameKO: '비르벤빌트 대공전차',
    filters: [FILTER_TABLE.ANTI_INFANTRY.en, FILTER_TABLE.ANTI_AIRCRAFT.en, FILTER_TABLE.MEDIUM_TANK.en],
    briefText: '대보병 / 대공',
  },
};

export const getMissingSquadData = (squadId: string) => {
  return (
    missingSquadDataTable[squadId] ?? {
      id: squadId,
      name: '',
      nameKO: '',
    }
  );
};
